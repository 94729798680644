exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-quote-tsx": () => import("./../../../src/pages/quote.tsx" /* webpackChunkName: "component---src-pages-quote-tsx" */),
  "component---src-pages-request-quote-tsx": () => import("./../../../src/pages/request-quote.tsx" /* webpackChunkName: "component---src-pages-request-quote-tsx" */),
  "component---src-templates-about-us-index-tsx": () => import("./../../../src/templates/about-us/index.tsx" /* webpackChunkName: "component---src-templates-about-us-index-tsx" */),
  "component---src-templates-contact-us-index-tsx": () => import("./../../../src/templates/contact-us/index.tsx" /* webpackChunkName: "component---src-templates-contact-us-index-tsx" */),
  "component---src-templates-enhanced-contact-us-index-tsx": () => import("./../../../src/templates/enhanced-contact-us/index.tsx" /* webpackChunkName: "component---src-templates-enhanced-contact-us-index-tsx" */),
  "component---src-templates-enhanced-fleet-list-index-tsx": () => import("./../../../src/templates/enhanced-fleet-list/index.tsx" /* webpackChunkName: "component---src-templates-enhanced-fleet-list-index-tsx" */),
  "component---src-templates-fleet-list-index-tsx": () => import("./../../../src/templates/fleet-list/index.tsx" /* webpackChunkName: "component---src-templates-fleet-list-index-tsx" */),
  "component---src-templates-frequently-asked-questions-index-tsx": () => import("./../../../src/templates/frequently-asked-questions/index.tsx" /* webpackChunkName: "component---src-templates-frequently-asked-questions-index-tsx" */),
  "component---src-templates-home-index-tsx": () => import("./../../../src/templates/home/index.tsx" /* webpackChunkName: "component---src-templates-home-index-tsx" */),
  "component---src-templates-news-and-advisories-articles-inside-page-index-tsx": () => import("./../../../src/templates/news-and-advisories/articles-inside-page/index.tsx" /* webpackChunkName: "component---src-templates-news-and-advisories-articles-inside-page-index-tsx" */),
  "component---src-templates-news-and-advisories-index-tsx": () => import("./../../../src/templates/news-and-advisories/index.tsx" /* webpackChunkName: "component---src-templates-news-and-advisories-index-tsx" */),
  "component---src-templates-privacy-policy-index-tsx": () => import("./../../../src/templates/privacy-policy/index.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-index-tsx" */),
  "component---src-templates-rate-calculator-index-tsx": () => import("./../../../src/templates/rate-calculator/index.tsx" /* webpackChunkName: "component---src-templates-rate-calculator-index-tsx" */),
  "component---src-templates-services-index-tsx": () => import("./../../../src/templates/services/index.tsx" /* webpackChunkName: "component---src-templates-services-index-tsx" */),
  "component---src-templates-track-and-trace-index-tsx": () => import("./../../../src/templates/track-and-trace/index.tsx" /* webpackChunkName: "component---src-templates-track-and-trace-index-tsx" */),
  "component---src-templates-user-guide-index-tsx": () => import("./../../../src/templates/user-guide/index.tsx" /* webpackChunkName: "component---src-templates-user-guide-index-tsx" */),
  "component---src-templates-vessel-schedule-index-tsx": () => import("./../../../src/templates/vessel-schedule/index.tsx" /* webpackChunkName: "component---src-templates-vessel-schedule-index-tsx" */),
  "slice---src-shared-core-footer-footer-tsx": () => import("./../../../src/shared/core/footer/Footer.tsx" /* webpackChunkName: "slice---src-shared-core-footer-footer-tsx" */),
  "slice---src-shared-core-header-header-tsx": () => import("./../../../src/shared/core/header/Header.tsx" /* webpackChunkName: "slice---src-shared-core-header-header-tsx" */)
}

